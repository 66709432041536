<template>
  <b-modal
    v-model="isModalVisible"
    size="md"
    dialog-class="mt-md-5 p-0 rounded-5"
    content-class="p-2"
    footer-class="border-top-0"
    modal-class="modal-custom"
    header-class="border-bottom-0"
    @ok="submit"
    @cancel="reset"
    
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase font-weight-bold">
        {{ $t("page.newProject.newAnalysis") }}
      </h5>
    </template>

    <template v-slot:default v-if="currentStep === 'file-selection'">
      <p class="font-weight-bold label">
        {{ $t("name") }}
      </p>
      <b-form-input v-model="newProjectName"> </b-form-input>
      <div
        v-if="!loadingVideo && !showVideoCaption"
        class="upload-section-wrapper mt-4 rounded"
        :class="{ 'custom-shadow': dragoverActive }"
      >
        <div
          @click="openUploadDialog"
          class="upload-section rounded h-100"
          @drop.prevent="uploadLocalFile"
          @dragover.prevent="dragover"
          @dragenter="dragoverActive = true"
          @dragleave="dragoverActive = false"
        >
          <input
            :accept="getMimeTypes"
            ref="inputUpload"
            type="file"
            name="file-input"
            :disabled="!isUploadValid"
            @change="uploadLocalFile"
          />
          <b-row
            class="no-gutters h-100 justify-content-center"
            align-v="center"
          >
            <b-col class="col-12 text-center">
              <img
                src="./../../assets/general-icons/upload-alt.svg"
                class="upload__icon"
              />
              <p class="mb-0 font-weight-bold text-emotiva-pink">
                {{ dropFileMsg }}
              </p>
              <p class="mb-1 text-emotiva-pink">{{ $t("or") }}</p>
              <p
                variant="outline-secondary"
                class="mx-auto text-emotiva-pink"
                size="sm"
              >
                {{ $t("button.chooseFile") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else class="video-preview">
        <b-row
          v-if="loadingVideo"
          class="no-gutters h-100 w-100"
          align-v="center"
        >
          <b-col class="col-12 text-center text-emotiva-pink">
            <b-icon
              icon="arrow-clockwise"
              animation="spin-pulse"
              font-scale="4"
              variant="white"
            ></b-icon>
            <div class="text-uppercase">
              {{ $t("loading") }}
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col
            class="d-flex align-items-center emotiva-pink-background-light"
          >
            <div class="d-flex align-items-center justify-start">
              <!-- THUMBNAIL -->
              <span v-if="fileInfo.file.type.startsWith('image/')">
                <img
                  :src="`${fileInfo.src}`"
                  alt=""
                  ref="image"
                  class="small-image"
                  @load="onImageLoad"
                />
              </span>
              <span v-else-if="fileInfo.file.type.startsWith('video/')">
                <video
                  class="small-image"
                  preload="metadata"
                  ref="video"
                  hidden
                  @canplay="onVideoLoad"
                  :src="`${fileInfo.src}#t=1.5`"
                ></video>
                  <canvas hidden ref="canvas" class="small-image"></canvas>
              </span>
              <span class="ml-3 textOverflow">{{ fileInfo.file.name }}</span>
            </div>
            <div class="d-flex justify-end">
              <b-icon
                class="ml-4"
                icon="x"
                @click="resetUploadForm"
                style="cursor: pointer"
                font-scale="1.5"
              ></b-icon>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
    <!-- Asset selection -->
    <template v-if="currentStep === 'viewer-selection'">
      <p class="font-weight-bold">{{ $t("page.newProject.projectType") }}</p>
      <b-row>
        <b-col
          :class="[
            'viewer-option m-2',
            selectedViewer === viewerItem.value ? 'selected-viewer' : '',
            index !== 0 ? 'disabled-viewer' : ''
          ]"
          v-for="(viewerItem, index) in viewers"
          :key="index"
          @click="index === 0 ? setViewer(viewerItem.value) : null"
        >
          <img class="viewer-icon" :src="viewerItem.icon" alt="" />
          <h6 class="mt-2">{{ viewerItem.name }}</h6>
        </b-col>
      </b-row>
    </template>

    <template v-if="currentStep === 'analysisInProgress'"> </template>

    <!-- Viewer selection -->

    <template v-slot:modal-footer="{ ok, cancel }">
      <!-- Continue -->
      <div class="footer-align-right">
        <div v-if="currentStep === 'file-selection'">
          <b-button
            variant="none"
            style="color:var(--emotiva-white-text)"
            class="mr-3 justify-content-center align-items-center bold action-btn btn-blue-border"
            @click="cancel"
            >{{ $t("button.cancel") }}</b-button
          >
          <b-button
            variant="none"
            style="color: #fff;"
            class="justify-content-center align-items-center bold emotiva-pink-background action-btn"
            :disabled="!isFormValid()"
            @click="nextStep('viewer-selection')"
            >{{ $t("button.continue") }}</b-button
          >
        </div>

        <!-- Start analysis -->
        <div v-else>
          <b-button
            variant="white"
            class="mr-3 action-btn justify-content-center align-items-center bold btn-blue-border"
            @click="cancel"
            >{{ $t("button.cancel") }}</b-button
          >
          <b-button
            variant="none"
            style="color:#fff"
            class="justify-content-center align-items-center bold emotiva-pink-background action-btn"
            :disabled="!isFormValid() || selectedViewer === ''"
            @click="ok"
          >
            <img
              src="../../assets/general-icons/startAnalysis-icon.svg"
              alt=""
            />
            <span class="ml-2">{{ $t("button.startAnalysis") }}</span></b-button
          >
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import uniqueId from "lodash/uniqueId";
export default {
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
    projectName: {
      type: String,
      required: false,
      default: "",
    },
    projectType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      analysisType: ["Standard", "Type1", "Type2", "Type3"],
      loadingVideo: false,
      showVideoCaption: false,
      dragoverActive: false,
      fileInfo: {},
      thumbnailBase64: "",
      scale: 0.25,
      totalAssets: 0,
      mimeTypes: ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/png'
        , 'video/mp5'
        , 'image/gif'
        , 'video/webm'
        , 'image/webp'
      ],
      newProjectName: "",
      steps: ["file-selection", "viewer-selection"],
      currentStep: "file-selection",
      viewers: [
        {
          name: this.$t("standard"),
          value: "standard",
          icon: require("../../assets/viewer-icons/standard-viewer.svg"),
        },
        {
          name: this.$t("instagram"),
          value: "instagram",
          icon: require("../../assets/viewer-icons/instagram-viewer.svg"),
        },
        {
          name: this.$t("facebook"),
          value: "facebook",
          icon: require("../../assets/viewer-icons/facebook-viewer.svg"),
        },
        // {
        //   name: this.$t("youtube"),
        //   value: "youtube",
        //   icon: require("../../assets/viewer-icons/youtube-viewer.svg"),
        // },
        {
          name: this.$t("X"),
          value: "x",
          icon: require("../../assets/viewer-icons/x-viewer.svg"),
        },
        {
          name: this.$t("tiktok"),
          value: "tiktok",
          icon: require("../../assets/viewer-icons/tiktok-viewer.svg"),
        },
      ],
      selectedViewer: "",
      // mimeTypes: ["image/*", "video/*"],
    };
  },
  computed: {
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      },
    },

    getMimeTypes() {
      return this.mimeTypes.join(", ");
    },

    isUploadValid() {
      return true;
    },
    dropFileMsg() {
      return this.$t("page.stimulus.dropFile");
    },
    
    getMediaType() {
      return this.fileInfo.file.type;
    },
  },
  methods: {
    isFormValid() {

      return (
        this.newProjectName.length > 0 &&
        this.fileInfo &&
        this.fileInfo.file &&
        this.fileInfo.file.name &&
        this.fileInfo.file.name.length > 0 &&
        this.fileInfo.file.type !== ""
      );
    },
    async submit() {
      let folderId = "";
      if (this.$route.name === "Folder" && this.$route.params.id)
        folderId = this.$route.params.id;
      const payload = {
        viewer: this.selectedViewer,
        file: this.fileInfo,
        projectName: this.newProjectName,
        folderId: folderId,
      };
      this.$store.commit("loader/SET_LOADER", {});
      await this.$store
        .dispatch("project/createPredictProjectByClientId", payload)
        .then(()=>{
          this.$store.dispatch("project/retrieveProjectsByClientId")
          this.$emit("submit");
        })
        .catch(() => {
          this.$store.commit("error/SET_ERROR", {titleText:"dialog.error.subscriptionNotFoundMessage.titleText", messageText: "dialog.error.subscriptionNotFoundMessage.messageText" }, { root: true });
        })
        .finally(() => {
          this.$store.dispatch("loader/close")
          // this.$emit("submit");
        });
      
      this.reset()
    },
    startAnalysis() {},
    nextStep(step) {
      this.currentStep = step;
    },
    uploadLocalFile(e) {
      const src = e.dataTransfer || e.target;
      const uploadedFiles = src.files;
      if (!uploadedFiles) return;
      else if (uploadedFiles.length > 1) {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.stimulus.errors.manyUploadedFiles.text",
        });
        return;
      }

      const MAX_MB_SIZE = 50;
      if (uploadedFiles[0].size / Math.pow(1024, 2) > MAX_MB_SIZE) {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.stimulus.errors.fileSizeExceed.text",
          messageParams: { value: MAX_MB_SIZE },
        });
        return;
      }

      this.fileInfo.file = uploadedFiles[0];

      if (!this.isValidFileType(this.fileInfo.file.type)) {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.stimulus.errors.invalidFileFormat.text",
        });
        return;
      }

      this.dragoverActive = false;
      this.loadingVideo = true;
      this.setDataURL();
    },
    onImageLoad() {
      this.loadingVideo = false;
      this.$store.commit("project/SET_PROJECT_THUMBNAIL",this.fileInfo.file)
      this.showVideoCaption = true;
    },
    onVideoLoad() {
      this.loadingVideo = false;
      this.showVideoCaption = true;
      this.$refs.video.hidden = false;
      this.fileInfo._id = uniqueId("file_");

      const canvas = this.$refs.canvas;
      const video = this.$refs.video;

      canvas.width = video.videoWidth; // * this.scale;
      canvas.height = video.videoHeight; // * this.scale;

      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      // Get the base64 representation of the canvas content
      this.thumbnailBase64 = canvas.toDataURL();
      this.$store.commit("project/SET_PROJECT_THUMBNAIL",this.base64ToFile(this.thumbnailBase64))
    },
    base64ToFile(dataURI) {
      var arr = dataURI.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `projectThumbnail.png`, { type: mime });
    },
    openUploadDialog() {
      this.$refs.inputUpload.click();
    },
    isValidFileType(type) {
      return this.mimeTypes.includes(type);
    },
    dragover(event) {
      event.dataTransfer.dropEffect = "move";
    },
    setDataURL() {
      this.fileInfo.src = URL.createObjectURL(this.fileInfo.file);
      this.$nextTick(() => {
        if (this.$refs.video) {
          this.$refs.video.onloadeddata = () => {
            URL.revokeObjectURL(this.fileInfo.src);
          };
        }
      });
    },
    resetUploadForm() {
      this.loadingVideo = false;
      this.showVideoCaption = false;
      this.fileInfo = {};
      if (this.$refs.video) this.$refs.video.hidden = true;
    },
    setViewer(viewer) {
      this.selectedViewer = viewer;
    },
    reset(){
      this.resetUploadForm();
      this.newProjectName="";
      this.selectedViewer="";
      this.nextStep('file-selection')
    }
  },
};
</script>

<style scoped>
.modal-custom .modal-content {
}

.disabled-viewer {
  opacity: 0.5;
  pointer-events: none; /* This prevents interaction with the element */
}

.btn-blue-border {
  border: 2px solid #cbd2e0;
  /* Change border-width to 2px */
  border-color: #cbd2e0;
}

.custom-modal-header {
  border-bottom: none;
}

.upload-section-wrapper,
.video-preview {
  height: 141px;
  cursor: pointer;
}

.upload-section-wrapper {
  height: 141px;
  border: 2px dashed var(--emotiva-pink);
  padding: 2px;
}

.custom-shadow {
  box-shadow: inset 0 0 20px 1px var(--gray);
}

.upload-section input {
  display: none;
}

.form__overlay {
  z-index: 0;
}

.upload__icon {
  width: 20px;
  height: 20px;
}

.btn-outline-secondary:hover {
  background-color: #6c757d;
}

.custom-control.custom-control-inline.custom-radio
  >>> .custom-control-label::before,
.custom-control.custom-control-inline.custom-radio
  >>> .custom-control-label::after {
  top: 0.1rem;
}

.solid-border-black {
  border: 3px solid #1a202c;
}

.footer-align-left {
  display: flex;

  width: 100%;
}

.bold {
  font-weight: bold;
}

.input-field {
  max-width: 600px;
}

.label {
  padding: 0;
  margin-bottom: 10px;
}

.small-image {
  height: 40px;
  width: 40px;
}

.textOverflow {
  width: 350px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.viewer-icon {
  height: 22px;
  width: 22px;
}

.viewer-option {
  border-radius: 10px;
  border: 1px solid #cbd2e0;
  height: 96px;
  min-width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected-viewer {
  background-color: #f2e7fe;
}
.text-emotiva-pink {
  color: var(--emotiva-pink);
}

.action-btn {
  border-radius: 26px;
  height: 46px;
  min-width: 100px;
}
/* video {
  object-fit: fill;
  overflow: hidden;
} */
</style>
